import React, { ReactElement } from 'react';
import { graphql, Link } from 'gatsby';
import { withErrorBoundary } from '../components/error-boundary';
import SEO from '../components/seo';
import { PostCardContainer } from '../components/post-card/post-card-container';
import { PostCard } from '../components/post-card';
import { Alert, AlertVariant } from '../components/alert';
import { TextLink, TextLinkVariant } from '../components/design';
import HeroLayout from '../components/layouts/hero-layout';
import { HeroElement } from '../components/layouts';

export interface TagTemplateProps {
  pageContext: {
    count: number;
    tag: string;
  };
  data: any; // TODO Use correct typedef
}

// TODO Extract frontmatter fragment
export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, hidden: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            description
            tags
            category
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 48, height: 48)
              }
            }
          }
        }
      }
    }
  }
`;

export const TagTemplate: React.FC<TagTemplateProps> = withErrorBoundary({
  scope: 'tag-template',
})(({ pageContext, data }: TagTemplateProps): ReactElement => {
  const { tag, count } = pageContext;
  const { edges } = data.allMdx;
  const hero = (
    <HeroElement
      title={tag}
      description={`A collection of ${count} post${count > 1 ? 's' : ''} tagged as #${tag}`}
    >
      <TextLink as={Link} to="/blog/tags" variant={TextLinkVariant.ACCENT}>
        See all tags
      </TextLink>
    </HeroElement>
  );
  return (
    <HeroLayout hero={hero}>
      <SEO title={`Blog | Posts about ${tag}`} description="Blog posts by tags" />
      {edges.length > 0 ? (
        <PostCardContainer>
          {edges.map(
            ({
              node: {
                id,
                frontmatter,
                fields: { slug },
              },
            }: any) => (
              <PostCard key={id} {...frontmatter} path={slug} />
            ),
          )}
        </PostCardContainer>
      ) : (
        <Alert title="No posts were found for this tag" variant={AlertVariant.INFO} />
      )}
    </HeroLayout>
  );
});
TagTemplate.displayName = 'TagTemplate';
export default TagTemplate;
